import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  OAuthEvent,
  OAuthService
} from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private authConfig;
  private userPuid: string = '';
  private redirectUriPath: string = '';
  private isLogout: boolean = false;
  constructor(readonly oauthService: OAuthService, private http: HttpClient, private cookieService: CookieService,
    private toastr: ToastrService) {
    this.loadAuthConfig()
      .then((_) => {
        this.intialize();
      })
  }

  intialize() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    this.getTheRedirectUriPathfromUrl();
    this.oauthService.events.subscribe((event: OAuthEvent) => {
      if (event.type === 'token_received') {
        if (this.oauthService.hasValidAccessToken()) {
          this.toastr.success('Access Token Received', 'Valid Access Token');
          this.sendToken()
            .subscribe(data => {
              this.redirectToHelium(data);
            },
              (error) => {
                this.toastr.error(error);
                this.logout('Invalid Access Token', 'Try Again!');
              });
        }
      }
    });

    const url =
      this.authConfig.issuer +
      '/.well-known/openid-configuration';

    // The convenience method mentioned in the docs (loadDiscoveryDocumentAndLogin) won't work
    // since we need a way to modify the token endpoint
    if (!this.isLogout) {
      this.oauthService
        .loadDiscoveryDocument(url)
        .then((_) => {
          return this.oauthService.tryLoginCodeFlow();
        })
        .then((_) => {
          this.ValdidateAccessToken();
        })
        .catch((err) => {
          // In that case, simply retry the login.
          this.toastr.error(err);
          this.cookieService.deleteAll('/', this.authConfig.cookieDomain);
          this.oauthService.initCodeFlow();
        });
    }
  }

  redirectToHelium(data: any) {
    if (data) {
      let redirect = this.getTheRedirectPath();
      window.location.href = this.authConfig.helium + '/' + redirect + "?puid=" + this.userPuid;
    }
    else {
      this.logout('Invalid Access Token','Try Again!');
    }
  }

  ValdidateAccessToken() {
    if (this.oauthService.hasValidAccessToken()) {
      this.sendToken().
        subscribe(data => {
          this.redirectToHelium(data);
        });
    }
    if (!this.oauthService.hasValidAccessToken()) {
      this.cookieService.deleteAll('/', this.authConfig.cookieDomain);
      this.oauthService.initCodeFlow();
    }
  }

  getTheRedirectUriPathfromUrl() {
    var url = document.location.href;
    if (!url.includes('?code=')) localStorage.removeItem('redirectUrl');
    var urlAndQueryString = url.split("?");
    if (urlAndQueryString.length > 1) {
      var queryValue = urlAndQueryString[1].split("=");
      if (queryValue.length > 1 && queryValue[0] == this.authConfig.queryStringKey) {
        this.redirectUriPath = queryValue[1];
        if (this.redirectUriPath !== '' && this.redirectUriPath !== '/') {
          localStorage.setItem(this.authConfig.cookieRedirectKey, this.redirectUriPath);
        }
      }
      else if (urlAndQueryString[1] == this.authConfig.logoutqueryparam) {
        this.logout("user has been sucessfully logout", "logout")
      }
    }
    else if (urlAndQueryString.length == 1) {
      if (urlAndQueryString[0] == this.authConfig.logoutqueryparam) {
        this.logout("user has been sucessfully logout", "logout")
      }
    }
  }

  login() {

    const url =
      this.authConfig.issuer +
      '/.well-known/openid-configuration';

    // The convenience method mentioned in the docs (loadDiscoveryDocumentAndLogin) won't work
    // since we need a way to modify the token endpoint
    this.oauthService
      .loadDiscoveryDocument(url)
      .then((_) => {
        this.cookieService.deleteAll('/', this.authConfig.cookieDomain);
        return this.oauthService.tryLoginCodeFlow();
      })
      .then((_) => {
        this.ValdidateAccessToken();
      })
      .catch((err) => {
        // In that case, simply retry the login.
        this.cookieService.deleteAll('/', this.authConfig.cookieDomain);
        this.toastr.error(err);
        this.oauthService.initCodeFlow();
      });
  }

  refresh() {
    this.oauthService.silentRefresh();
  }

  logout(message: string, title: string) {
    this.isLogout = true;
    this.toastr.error(message, title); 
    this.cookieService.deleteAll('/', this.authConfig.cookieDomain);
    this.oauthService.logOut();
    this.logoutSession();
  }

  logoutSession() {
    window.location.href =  this.authConfig.ping_end_session_endpoint;
  }

  sendToken() {
    const RefreshToken = this.oauthService.getRefreshToken();
    const nonce = localStorage.getItem('nonce');
    const id_token_expires_at = localStorage.getItem('id_token_expires_at');
    const PKCE_verifier = localStorage.getItem('PKCE_verifier');
    const expires_at = localStorage.getItem('expires_at');
    const access_token_stored_at = localStorage.getItem('access_token_stored_at');
    const id_token_stored_at = localStorage.getItem('id_token_stored_at');
    const id_token = undefined;
    const access_token = localStorage.getItem('access_token');
    var id_token_claims_obj = localStorage.getItem('id_token_claims_obj');
    var id_token_claims_obj_JSON = JSON.parse(localStorage.getItem('id_token_claims_obj'));
    id_token_claims_obj_JSON.groups = undefined;
    id_token_claims_obj = JSON.stringify(id_token_claims_obj_JSON);
    const session_state = localStorage.getItem('session_state');
    const token_obj = {
      "nonce": nonce,
      "id_token_expires_at": id_token_expires_at,
      "PKCE_verifier": PKCE_verifier,
      "expires_at": expires_at,
      "access_token_stored_at": access_token_stored_at,
      "id_token_stored_at": id_token_stored_at,
      "id_token": id_token,
      "access_token": access_token,
      "profile": id_token_claims_obj_JSON.sub,
      "session_state": session_state,
      "refresh_token": RefreshToken,
      "scope": "openid  email",
      "state": undefined,
      "token_type": "Bearer"
    }
    const FAMS = JSON.stringify(token_obj);
    this.userPuid = JSON.parse(id_token_claims_obj).sub.split('@')[0];
    var doRedirectUri = this.authConfig.idService + 'api/fams/DoRedirect';
    return this.http.get(doRedirectUri, { headers: this.authHeader(access_token, FAMS), withCredentials : true });
  }


  loadAuthConfig() {
    return this.http.get('../assets/auth-config.json')
      .toPromise()
      .then(data => {
        this.authConfig = data;
      });
  }


  private getTheRedirectPath() {
    var redirect = localStorage.getItem("redirectUrl") ? localStorage.getItem("redirectUrl") : '';
    if (redirect) {
      if (redirect.substring(0, 1) == '/') {
        redirect = redirect.substring(1, redirect.length);
      }
    }
    return redirect;
  }

  private authHeader(token: any, FAMS: any): HttpHeaders {
    return new HttpHeaders({
      'Authorization': `Bearer ` + token,
       'OpenIdTokens' : FAMS
    })
  }
}
